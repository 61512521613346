@font-face {
    font-family: 'Rift';
    src: url('../fonts/Rift-Regular.eot');
    src: url('../fonts/Rift-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Rift-Regular.woff2') format('woff2'),
         url('../fonts/Rift-Regular.woff') format('woff'),
         url('../fonts/Rift-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Rift';
    src: url('../fonts/Rift-Medium.eot');
    src: url('../fonts/Rift-Medium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Rift-Medium.woff2') format('woff2'),
         url('../fonts/Rift-Medium.woff') format('woff'),
         url('../fonts/Rift-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Rift';
    src: url('../fonts/Rift-Demi.eot');
    src: url('../fonts/Rift-Demi.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Rift-Demi.woff2') format('woff2'),
         url('../fonts/Rift-Demi.woff') format('woff'),
         url('../fonts/Rift-Demi.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Rift';
    src: url('../fonts/Rift-Bold.eot');
    src: url('../fonts/Rift-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Rift-Bold.woff2') format('woff2'),
         url('../fonts/Rift-Bold.woff') format('woff'),
         url('../fonts/Rift-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
