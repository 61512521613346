.hero {
  background-size: cover;
  background-position: bottom right;
  height: (960 * 100vw / 1920);
  margin: 0 0 (90 * 100vw / 1920);
  padding: 0 (105 * 100vw / 1920) (70 * 100vw / 1920);
  align-items: flex-end;
  display: flex;
  position: relative;
  z-index: 25;
  @include media($w1024) {
    background-position: center center;
    height: 600px;
    margin: 0 0 30px;
    padding: 0 30px 30px;
  }
  @include media($w768) {
    height: 550px;
  }
  @include media($w560) {
    height: 500px;
  }
  &__copy {
    flex: 0 0 100%;
    &__type {
      font-size: (20 * 100vw / 1920);
      font-weight: 700;
    	line-height: (24 * 100vw / 1920);
      letter-spacing: 0.2em;
      margin: 0 0 (50 * 100vw / 1920);
      @include media($w1024) {
        font-size: 16px;
      	line-height: 20px;
        margin: 0 0 30px;
      }
      @include media($w1024) {
        font-size: 15px;
      	line-height: 19px;
        margin: 0 0 25px;
      }
    }
    &__title {
      img {
        width: (430 * 100vw / 1920);
        @include media($w1024) {
          width: 260px;
        }
        @include media($w768) {
          width: 240px;
        }
        @include media($w560) {
          width: 200px;
        }
      }
    }
    &__premiere {
      margin: (-15 * 100vw / 1920) 0 (10 * 100vw / 1920);
      justify-content: space-between;
      align-items: center;
      display: flex;
      position: relative;
      @include media($w1024) {
        margin: 10px 0;
      }
      @include media($w560) {
        margin: 5px 0;
      }
      &:before {
        content: '';
        background-color: $brown;
        width: 100vw;
        height: (10 * 100vw / 1920);
        position: absolute;
        z-index: 75;
        top: calc(50% - (5 * 100vw / 1920));
        right: 100%;
        @include media($w1024) {
          display: none;
        }
      }
      &__date {
        background-color: $brown;
        font-size: (28 * 100vw / 1920);
        font-weight: 500;
      	line-height: (33 * 100vw / 1920);
        color: $gold;
        padding: 0 (30 * 100vw / 1920) 0 0;
        position: relative;
        z-index: 50;
        @include media($w1024) {
          background-color: transparent;
          font-size: 20px;
        	line-height: 24px;
          padding: 0;
        }
        @include media($w768) {
          font-size: 18px;
        	line-height: 22px;
        }
        @include media($w560) {
          font-size: 16px;
        	line-height: 20px;
        }
      }
      &__trailer {
        padding: 0 0 0 (30 * 100vw / 1920);
        justify-content: flex-end;
        display: flex;
        position: relative;
        z-index: 25;
        &:before {
          content: '';
          background-color: $gold;
          width: 100vw;
          height: (1 * 100vw / 1920);
          position: absolute;
          z-index: 25;
          top: 50%;
          right: 100%;
          @include media($w1024) {
            display: none;
          }
        }
        a {
          font-size: (20 * 100vw / 1920);
        	line-height: (24 * 100vw / 1920);
          align-items: center;
          display: inline-flex;
          @include media($w1024) {
            font-size: 16px;
          	line-height: 20px;
          }
          @include media($w768) {
            font-size: 15px;
          	line-height: 19px;
          }
          img {
            width: (118 * 100vw / 1920);
            margin: 0 0 0 (30 * 100vw / 1920);
            @include media($w1024) {
              width: 60px;
              margin: 0 0 0 20px;
            }
            @include media($w768) {
              width: 50px;
            }
            @include media($w560) {
              margin: 0 0 0 15px;
            }
          }
        }
      }
    }
    &__about {
      p {
        max-width: (560 * 100vw / 1920);
        margin: 0 0 (65 * 100vw / 1920);
        @include media($w1024) {
          max-width: 55%;
          margin: 0 0 30px;
        }
        @include media($w768) {
          max-width: none;
          margin: 0 0 25px;
        }
      }
      a {
        font-size: (20 * 100vw / 1920);
        font-weight: 700;
      	line-height: (24 * 100vw / 1920);
        letter-spacing: 0.2em;
        padding: 0 (60 * 100vw / 1920) 0 0;
        display: inline-block;
        position: relative;
        @include media($w1024) {
          font-size: 16px;
          line-height: 20px;
          padding: 0 30px 0 0;
        }
        @include media($w768) {
          font-size: 15px;
          line-height: 19px;
        }
        &:after {
          @include translateY;
          content: '';
          background-image: url(../images/icon--link--arrow-down.svg);
          background-size: cover;
          width: (26 * 100vw / 1920);
          height: (14 * 100vw / 1920);
          position: absolute;
          right: 0;
          @include media($w1024) {
            width: 16px;
            height: 8px;
          }
        }
      }
    }
  }
}
