.content {
  padding: 0 (105 * 100vw / 1920);
  display: flex;
  position: relative;
  z-index: 50;
  @include media($w1024) {
    padding: 0 30px;
  }
  @include media($w768) {
    flex-wrap: wrap;
    justify-content: center;
  }
  &__sidebar {
    flex: 0 0 (396 * 100vw / 1920);
    @include media($w1024) {
      flex: 0 0 25%;
    }
    @include media($w768) {
      flex: 0 0 250px;
      margin: 0 0 30px;
    }
    &__cover {
      border-radius: (10 * 100vw / 1920);
      overflow: hidden;
      position: relative;
      @include media($w1024) {
        border-radius: 5px;
      }
      &:before {
        @include translate;
        content: '';
        background-image: url(../images/icon--play.svg);
        background-size: cover;
        width: (118 * 100vw / 1920);
        height: (118 * 100vw / 1920);
        pointer-events: none;
        position: absolute;
        z-index: 50;
        @include media($w1024) {
          width: 60px;
          height: 60px;
        }
        @include media($w768) {
          width: 50px;
          height: 50px;
        }
      }
      a {
        display: inline-block;
        position: relative;
        z-index: 25;
        img {
          width: 100%;
        }
      }
    }
  }
  &__main {
    padding: 0 0 0 (105 * 100vw / 1920);
    @include media($w1024) {
      padding: 0 0 0 30px;
    }
    @include media($w768) {
      flex: 0 0 100%;
      padding: 0;
    }
    &__top {
      border-bottom: (2 * 100vw / 1920) solid $gold;
      margin: 0 0 (45 * 100vw / 1920);
      padding: 0 0 (55 * 100vw / 1920);
      display: flex;
      @include media($w1024) {
        border-bottom-width: 1px;
        margin: 0 0 30px;
        padding: 0 0 30px;
      }
      @include media($w560) {
        flex-wrap: wrap;
      }
      &__info {
        flex: 0 0 44%;
        @include media($w1024) {
          flex: 0 0 45%;
        }
        @include media($w560) {
          flex: 0 0 100%;
          margin: 0 0 30px;
        }
        &__title {
          font-size: (86 * 100vw / 1920);
          line-height: (103 * 100vw / 1920);
          @include media($w1024) {
            font-size: 44px;
            line-height: 44px;
            margin: 0 0 5px;
          }
          @include media($w768) {
            font-size: 36px;
            line-height: 36px;
          }
        }
        &__type {
          font-size: (28 * 100vw / 1920);
          line-height: (33 * 100vw / 1920);
          margin: 0 0 (40 * 100vw / 1920);
          @include media($w1024) {
            font-size: 18px;
            line-height: 24px;
            margin: 0 0 20px;
          }
        }
        &__info {
          li {
            font-size: (19 * 100vw / 1920);
            line-height: (23 * 100vw / 1920);
            color: $gold;
            @include media($w1024) {
              font-size: 16px;
              line-height: 20px;
            }
            @include media($w768) {
              font-size: 15px;
              line-height: 19px;
            }
            span {
              width: (155 * 100vw / 1920);
              color: $white;
              display: inline-block;
              @include media($w1024) {
                width: 50%;
              }
            }
          }
        }
      }
      &__gallery {
        flex: 0 0 56%;
        flex-wrap: wrap;
        justify-content: flex-end;
        display: flex;
        @include media($w1024) {
          flex: 0 0 55%;
          justify-content: flex-start;
        }
        @include media($w560) {
          flex: 0 0 100%;
        }
        &__list {
          flex: 0 0 100%;
          margin: 0 0 (23 * 100vw / 1920);
          flex-wrap: wrap;
          display: flex;
          @include media($w1024) {
            margin: 0 0 19px;
          }
          li {
            flex: 0 0 calc(100% / 4 - (9 * 100vw / 1920));
            margin: 0 (12 * 100vw / 1920) (12 * 100vw / 1920) 0;
            @include media($w1024) {
              flex: 0 0 calc(100% / 3 - 6px);
              margin: 0 9px 9px 0;
            }
            &:nth-child(4n+4) {
              margin: 0 0 (12 * 100vw / 1920);
              @include media($w1024) {
                margin: 0 9px 9px 0;
              }
            }
            &:nth-child(3n+3) {
              @include media($w1024) {
                margin: 0 0 9px;
              }
            }
            img {
              border-radius: (5 * 100vw / 1920);
              width: 100%;
              @include media($w1024) {
                border-radius: 5px;
              }
            }
          }
        }
        &__link {
          font-size: (17 * 100vw / 1920);
          font-weight: 700;
        	line-height: (21 * 100vw / 1920);
          letter-spacing: 0.2em;
          padding: 0 (45 * 100vw / 1920) 0 0;
          display: inline-block;
          position: relative;
          @include media($w1024) {
            font-size: 16px;
            line-height: 20px;
            padding: 0 20px 0 0;
          }
          @include media($w768) {
            font-size: 15px;
            line-height: 19px;
          }
          &:after {
            @include translateY;
            content: '';
            background-image: url(../images/icon--link--arrow-right.svg);
            background-size: cover;
            width: (14 * 100vw / 1920);
            height: (26 * 100vw / 1920);
            position: absolute;
            right: 0;
            @include media($w1024) {
              width: 8px;
              height: 16px;
            }
          }
        }
      }
    }
    &__bottom {
      &__descritpion {
        margin: 0 0 (45 * 100vw / 1920);
        @include media($w1024) {
          margin: 0 0 25px;
        }
      }
      &__cast {
        @include media($w768) {
          border-bottom: 1px solid $gold;
          padding: 0 0 30px;
        }
        &__title {
          font-size: (28 * 100vw / 1920);
          font-weight: 500;
          line-height: (33 * 100vw / 1920);
          margin: 0 0 (35 * 100vw / 1920);
          @include media($w1024) {
            font-size: 18px;
            line-height: 24px;
            margin: 0 0 20px;
          }
        }
        &__list {
          li {
            font-size: (19 * 100vw / 1920);
            line-height: (25 * 100vw / 1920);
            margin: 0 0 (20 * 100vw / 1920);
            align-items: center;
            display: flex;
            @include media($w1024) {
              font-size: 16px;
              line-height: 20px;
              margin: 0 0 12px;
            }
            @include media($w768) {
              font-size: 15px;
              line-height: 19px;
              margin: 0 0 10px;
            }
            &:last-child {
              margin: 0;
            }
            img {
              border-radius: 50%;
              width: (42 * 100vw / 1920);
              margin: 0 (20 * 100vw / 1920) 0 0;
              @include media($w1024) {
                width: 35px;
                margin: 0 15px 0 0;
              }
            }
            .name {
              flex: 0 0 (255 * 100vw / 1920);
              @include media($w1024) {
                flex: 0 0 150px;
              }
              @include media($w560) {
                flex: 0 0 40%;
              }
            }
            .role {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
