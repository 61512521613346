a {
	@include transition;
	text-decoration: none;
	color: $white;
	&:hover {
		color: $gold;
	}
}

p {
	font-size: (20 * 100vw / 1920);
	line-height: (24 * 100vw / 1920);
	margin: 0;
	@include media($w1024) {
		font-size: 16px !important;
		line-height: 20px !important;
	}
	@include media($w768) {
		font-size: 15px !important;
		line-height: 19px !important;
	}
}

strong, b {
	font-weight: 700;
}
