.header {
  width: 100%;
  padding: (65 * 100vw / 1920) (105 * 100vw / 1920) 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  @include media($w1024) {
    padding: 30px 30px 0;
  }
  &__logo {
    img {
      width: (293 * 100vw / 1920);
      @include media($w1024) {
        width: 200px;
      }
      @include media($w768) {
        width: 170px;
      }
    }
  }
  &__menu {
    margin: 0 (60 * 100vw / 1920) 0 auto;
    @include media($w1024) {
      margin: 0 30px 0 auto;
    }
    @include media($w768) {
      margin: 0 25px 0 auto;
    }
    @include media($w560) {
      margin: 0;
    }
    ul {
      display: flex;
      li {
        line-height: 0;
        & + li {
          margin: 0 0 0 (42 * 100vw / 1920);
          @include media($w1024) {
            margin: 0 0 0 20px;
          }
          @include media($w768) {
            margin: 0 0 0 15px;
          }
          @include media($w560) {
            padding: 0 0 2px;
          }
        }
        a {
          font-size: (20 * 100vw / 1920);
          line-height: (24 * 100vw / 1920);
          @include media($w1024) {
            font-size: 16px;
            line-height: 20px;
          }
          @include media($w768) {
            font-size: 15px;
            font-weight: 500;
            line-height: 19px;
          }
        }
      }
    }
  }
  &__social-media {
    display: flex;
    @include media($w560) {
      display: none;
    }
    li {
      & + li {
        margin: 0 0 0 (20 * 100vw / 1920);
        @include media($w1024) {
          margin: 0 0 0 15px;
        }
      }
      a {
        img {
          width: (32 * 100vw / 1920);
          @include media($w1024) {
            width: 26px;
          }
          @include media($w768) {
            width: 24px;
          }
        }
      }
    }
  }
}
