.director {
  padding: (150 * 100vw / 1920) 0 (180 * 100vw / 1920) (280 * 100vw / 1920);
  position: relative;
  z-index: 25;
  @include media($w1024) {
    padding: 60px 30px;
  }
  @include media($w768) {
    text-align: center;
    padding: 30px 30px 360px;
  }
  @include media($w560) {
    padding: 30px 30px 310px;
  }
  &__info {
    pointer-events: none;
    position: relative;
    z-index: 150;
    &__name {
      font-size: (173 * 100vw / 1920);
      font-weight: 600;
      line-height: (208 * 100vw / 1920);
      pointer-events: auto;
      margin: 0 0 (90 * 100vw / 1920);
      @include media($w1024) {
        font-size: 60px;
        line-height: 60px;
        margin: 0 0 20px;
      }
      @include media($w768) {
        font-size: 48px;
        line-height: 48px;
      }
      @include media($w560) {
        font-size: 42px;
        line-height: 42px;
        margin: 0 0 15px;
      }
      span {
        font-size: (72 * 100vw / 1920);
        @include media($w1024) {
          font-size: 60px;
        }
        @include media($w768) {
          font-size: 48px;
        }
        @include media($w560) {
          font-size: 42px;
        }
      }
    }
    &__bio {
      max-width: (890 * 100vw / 1920);
      pointer-events: auto;
      margin: 0 0 (65 * 100vw / 1920);
      @include media($w1024) {
        max-width: calc(100% - 300px);
        margin: 0 0 30px;
      }
      @include media($w768) {
        max-width: 100%;
      }
    }
    &__social-media {
      pointer-events: auto;
      display: flex;
      @include media($w768) {
        justify-content: center;
      }
      li {
        & + li {
          margin: 0 0 0 (20 * 100vw / 1920);
          @include media($w1024) {
            margin: 0 0 0 15px;
          }
        }
        a {
          img {
            width: (32 * 100vw / 1920);
            @include media($w1024) {
              width: 26px;
            }
            @include media($w768) {
              width: 24px;
            }
          }
        }
      }
    }
  }
  &__bg {
    width: 100%;
    pointer-events: none;
    position: absolute;
    z-index: 25;
    top: (-170 * 100vw / 1920);
    left: 0;
    @include media($w1024) {
      top: auto;
      bottom: 0;
    }
    @include media($w768) {
      width: 140%;
      left: -20%;
    }
    @include media($w560) {
      width: 170%;
      left: -35%;
    }
    img {
      width: 100%;
    }
  }
  &__position {
    width: 100%;
    font-size: (28 * 100vw / 1920);
    line-height: (33 * 100vw / 1920);
    color: $gold;
    padding: 0 0 0 (280 * 100vw / 1920);
    position: absolute;
    z-index: 50;
    top: (390 * 100vw / 1920);
    left: 0;
    @include media($w1024) {
      display: none;
    }
    span {
      padding: 0 (30 * 100vw / 1920) 0 0;
      display: inline-block;
      position: relative;
      &:before {
        content: '';
        background-color: $gold;
        width: 100vw;
        height: (1 * 100vw / 1920);
        position: absolute;
        top: 50%;
        left: 100%;
      }
    }
    &--mobile {
      font-size: 20px;
      line-height: 26px;
      margin: 0 0 25px;
      padding: 0;
      display: none;
      position: relative;
      top: auto;
      left: auto;
      @include media($w1024) {
        display: block;
      }
      @include media($w560) {
        margin: 0 0 20px;
      }
      span {
        color: $gold;
        padding: 0;
        &:before {
          display: none;
        }
      }
    }
  }
  &__photo {
    width: (530 * 100vw / 1920);
    position: absolute;
    z-index: 75;
    top: (125 * 100vw / 1920);
    right: (250 * 100vw / 1920);
    @include media($w1024) {
      width: 350px;
      top: auto;
      bottom: 0;
      right: 0;
    }
    @include media($w768) {
      @include translateX;
      position: absolute;
      right: auto;
      left: calc(50% + 15px);
    }
    @include media($w560) {
      width: 300px;
    }
    img {
      width: 100%;
    }
  }
  &__gradient {
    width: 100%;
    pointer-events: none;
    position: absolute;
    z-index: 100;
    top: (460 * 100vw / 1920);
    left: 0;
    @include media($w1024) {
      top: auto;
      bottom: 0;
    }
    @include media($w768) {
      width: 140%;
      left: -20%;
    }
    @include media($w560) {
      width: 170%;
      left: -35%;
    }
    img {
      width: 100%;
    }
  }
}
