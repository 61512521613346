* {
	@include box-sizing;
	font-family: 'Rift' !important;
	font-weight: 400;
	color: $white;
	&:before,
	&:after {
		@include box-sizing;
	}
}

::-moz-selection {
	background: $gold;
	color: $white;
}

::selection {
	background: $gold;
	color: $white;
}

body {
	background-color: $brown;
	margin: 0;
	padding: 0;
}

img {
	margin: 0;
	display: block;
	user-select: none;
}

/* --- [ global styles ] --- */

.wrapper {
	width: 100%;
	overflow: hidden;
	position: relative;
}

.clearfix {
	&:after {
		clear: both;
		display: table;
	}
}

.anchor {
	width: 100%;
	pointer-events: none;
	position: absolute;
	top: (-50 * 100vw / 1920);
	left: 0;
	@include media($w1024) {
		top: 30px;
	}
}
